import React from "react";

const privaPolicyStr = `Hopon.vc Privacy Policy
Effective date: 4/2/2020
This Privacy Statement (the "Privacy Statement") is provided by:
Hopon.vc ("The Company")
Introduction
This policy describes what information we collect when you use Hopon.vc’s sites, services, mobile applications, products, and content (“Services”). It also provides information about how we store, transfer, use, and delete that information, and what choices you have with respect to the information.
This policy applies to Hopon.vc’s online video meeting tool, including the website and mobile applications, and other Hopon.vc websites (collectively “the Websites”).
This policy applies where we are acting as a Data Controller with respect to the personal data of Consultants and Users of our Services; in other words, where we determine the purposes and means of the processing of that personal data. For content and data that you upload to or make available through the Service (“User Content”), you are responsible for ensuring this content is in accordance with our Terms of Service, and that the content is not violating other Consultants and Users’ privacy.
How we collect, process and store information
We in Hopon.vc are committed to safeguarding the privacy of our Consultants and Users. Our business model is to withhold a small fee from video calls and other services offered through the Hopon.vc Platform, and does not rely on widespread collection of general user data. We will only collect and process information that we need to deliver the service to you, and to continue to maintain and develop the service.
Hopon.vc may collect, store and process various kinds of data, with different legal grounds, as listed below. For the categories of data that require your consent, we will actively ask you for consent before collecting any data. You can give and revoke your consents at any time in your Settings page in https://Hopon.vc.com.
The following is a list of data we collect, process or store:
Consultant and User account information. To use Hopon.vc Service, Consultants and Consultants and Users might have to provide a valid email address, date of birth, and full name. The Consultant can also choose to enter a display name, add a profile picture and/or video, and short bio that will be used to represent them on their public profile page, and in conversations.


Transaction information. Consultants that register on Hopon.vc, as well as Consultants and Users of their Services might have to provide credit card information, billing email, banking information, location at the time of transaction and/or a billing address in order to complete a booking transaction.
The transaction data may be processed for the purpose of supplying the purchased services and keeping proper records of those transactions. This data may be used for the purpose of delivering the Services to you. Processing this information is required for fulfilling the contract we entered into with you, at your request (our Terms of Service). Additionally, this information needs to be retained in order to comply with accounting and tax regulation.
Service Information. When you interact with the Service, we automatically store a recording of a video call on our server with the purpose of using it exclusively for mitigating user disputes of the Service, as explained in our FAQs, and performing the contract we entered into with you, at your request (our Terms of Service). If dispute was not raised by the User within the time, defined in our FAQs, such recordings are automatically deleted.
Usage information. When you interact with the Services, we collect and process metadata to provide additional context about the way the Service is being used. The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our server software and our analytics tracking system.
Product Analytics data. Hopon.vc logs activities by you and other Consultants and Users when the Consultants and Users interact with our websites or apps, when a page or a room is visited or where there is a conversation. We will never collect or record the content in conversations.
Technical log data. Like most digital services, our servers automatically collect information when Websites or Services are accessed or used and record it in log files. This log data may include the Internet Protocol (IP) address, the address of the web page visited within the Services, browser type and settings, the date and time the Services were used, information about browser configuration and plugins, language preferences and cookie data.
Device information. Hopon.vc may collect and process information about devices used to access the Services, including type of device, what operating system is used, device settings, application IDs, unique device identifiers and crash data. Whether we collect and process some or all of this information depends on the type of device used and its settings.
Location information. We receive information from you and other third-parties that helps us approximate your location. We may, for example, use a business address submitted by your employer, or an IP address received from your browser or device to determine approximate location. Hopon.vc may also collect location information from devices in accordance with the consent process provided by your device.
The legal basis for this processing is our legitimate interests, namely using this data for the purpose of ensuring the proper administration of our website and business, analyzing the use of the website and services, monitoring and improving our website and services, improving the user experience, preventing abuse, and assisting Consultants and Users with support inquiries. For information about cookies and how to opt out of cookies, see our Cookie Policy.


Customer Support Information. We may process information that you send to us, should you choose to submit a ticket to our support email. If you contact us, we may use your Account, Transaction or Usage Information to respond.
Processing this information it is required for performing the contract we entered into with you, at your request (our Terms of Service), as well as our legitimate interest of handling your requests.


Service and transactional notifications. Sometimes we’ll send you emails about your account, service changes or new policies. You can’t opt out of this type of “service or transactional” emails (unless you delete your account) as they are necessary information for the Services.
The legal grounds for processing this information is that it is required for performing our commitment about communicating changes in plans and pricing to you in the contract we entered into with you, at your request (our Terms of Service).
Correspondence information. We may process information that you choose to share with us if you participate in a focus group, contest, activity or event, apply for a job, interact with our social media accounts or otherwise communicate with Hopon.vc
The correspondence data may be processed for the purposes of communicating with you and record-keeping.


Security
Video and audio transmitted in the Service is then sent directly between the participants in a room and is encrypted with client-generated encryption keys. In cases where a user is behind a strict firewall or NAT, video and audio need to be relayed via a TURN server, but end-to-end encryption is still maintained. Our video calling infrastructure is served on Amazon Web Services. You are connected to the nearest router when interfacing our video services.
Providing your personal data to others
We may share information about you with third parties in some circumstances, including: (1) with your consent; (2) to a service provider or partner who meets our data protection standards; (3) with academic or non-profit researchers, with aggregation, anonymization; (4) when we have a good faith belief it is required by law, such as pursuant to a subpoena or other legal process; (5) to protect the vital interest of others, when we have reason to believe that doing so will prevent harm to someone or illegal activities.
Our categories of service providers and partners are:
Consulting/infrastructure/storage providers
Payment processors
Analysis tools providers
Customer Support tools providers
Marketing and email providers
Recruiting tools providers
Internal communication tools providers
Business Transfers
We may disclose your personal data to any member of our group of companies (this means our subsidiaries, our ultimate holding company and all its subsidiaries) insofar as reasonably necessary for the purposes, and on the legal bases, set out in this policy.
In the case where we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different privacy policy, we will notify you in advance and give you the option to delete your data before the transfer.
International transfers of your personal data
In some circumstances your personal data may be transferred to countries outside the European Economic Area (EEA). You acknowledge that personal data that you submit for publication through our website or services may be available, via the internet, around the world. We cannot prevent the use (or misuse) of such personal data by others. For information about what types of content you are, see this Terms of Service
Retaining and deleting personal data
Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.
We will retain your personal data as follows:
Transaction information will be retained for a minimum period of 1 year and a maximum of 5 years. 
In some cases it is not possible for us to specify in advance the periods for which your personal data will be retained. In such cases, we will determine the period of retention based on the following criteria:
Account information will be retained until you decide to delete your account on Hopon.vc.
Information about you used for Product & Marketing communication will be retained as long as you have given us consent to use this information.
The period of retention of usage information will be determined based on the need for historical data to determine statistical validity and relevance for product decisions and technical monitoring.
Regardless of the provisions above, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.
Changes to this policy
We can change these Terms at any time. If a change is material, we’ll let you know before it takes effect. By using Hopon.vc on or after that effective date, you agree to the new Terms. If you don’t agree to them, you should contact Hopon.vc at support@Hopon.vc.com with the request to delete your account before they take effect, otherwise your use of the Service and Content will be subject to the new Terms.z
Managing and deleting your personal information
If you have a Hopon.vc account, you can access, and modify your personal information, in Settings. To delete your account, you should contact Hopon.vc at support@Hopon.vc.com. If you request to delete your account, your information and content will be unrecoverable after that time.  We may withhold personal information that you request to the extent permitted by law.
Your rights
As an individual you are granted rights according to the applicable data protection law:
The right to access to your personal data
The right to rectification of your personal data
The right to object to and restriction of our processing of your personal data
The right to data portability.
To exercise your rights or if you otherwise have any questions regarding our processing of your personal data, we encourage you to contact us as described below. However, we also notify you that you may raise complaint to a data protection authority.


For any questions about this privacy policy, please contact hello@Hopon.vc.com

`;

export default function PrivacyPolicyPage() {
  return <pre>{privaPolicyStr}</pre>;
}
