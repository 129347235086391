import React, { useEffect } from "react";
import "./styles.css";
import LandingPage from "./LandingPage";
import SignUpPage from "./SignUpPage";
import { Route, Switch, useLocation } from "react-router-dom";
import ReactGa from "react-ga";
import PrivacyPolicyPage from "./PrivacyPolicyPage";

ReactGa.initialize("UA-162797278-1");

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    ReactGa.pageview(location.pathname);
  }, [location]);
}

export default function App() {
  usePageViews();
  return (
    <Switch>
      <Route path="/signup">
        <SignUpPage />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicyPage />
      </Route>
      {/* make sure to keep this at the bottom */}
      <Route path="/">
        <LandingPage />
      </Route>
    </Switch>
  );
}
