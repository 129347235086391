import React from "react";
import "./styles.css";
import Lottie from "react-lottie";
import { useHistory, useLocation } from "react-router-dom";
import * as high5Animation from "./animations/high_5_anim.json";
import * as calenderUpdateAnimation from "./animations/calender_update_anim.json";
import * as hanginClinkAnimation from "./animations/hanging_clink_anim.json";
import Header from "./components/Header";

import ReactGa from "react-ga";
import TextLoop from "react-text-loop";
import mobileAppImg from "./images/booking-preview.jpg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function LandingPage() {
  let history = useHistory();
  let query = useQuery();
  function onStartBtnPress() {
    ReactGa.event({
      category: "Button",
      action: "Start Now button pressed"
    });
    let sourceQuery = "";
    if (query.get("source")) {
      sourceQuery = "?source=" + query.get("source");
    }
    history.push("/signup" + sourceQuery);
  }

  return (
    <div className="root">
      <div className="container">
        <Header />
        <section className="section-1">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <h3 className="secondary-text">easiest way to setup</h3>
            <h1>
              1-1 video calls with your{" "}
              <TextLoop
                mask={true}
                // interval={1500}
                springConfig={{ stiffness: 70, damping: 31 }}
              >
                <span>clients</span>
                <span>followers</span>
                <span>students</span>
              </TextLoop>
            </h1>
            <h3 className="secondary-text">and get paid</h3>
            <button
              className="desktop-visible start-btn"
              onClick={onStartBtnPress}
            >
              Create Account
            </button>
          </div>
          <div>
            <div
              style={{
                width: "100%",
                maxHeight: 600,
                overflow: "hidden"
              }}
            >
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: high5Animation,
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid meet"
                  }
                }}
                isStopped={false}
                isPaused={false}
              />
            </div>
            <button
              className="phone-visible start-btn"
              onClick={onStartBtnPress}
            >
              Create Account
            </button>
            <p
              style={{ textAlign: "center", paddingTop: 12 }}
              className="phone-visible secondary-text"
            >
              ⌄ or learn more ⌄
            </p>
          </div>
        </section>
        <section className="section-2">
          <div className="calndr-anim-container">
            <img alt="booking preview" src={mobileAppImg} width="100%" />
          </div>
          <div
            className="bullet-points-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div>
              <h2> Convert profile visits into new clients. </h2>
            </div>
            <div>
              <h3>🔗 Personalized Booking Page</h3>
              <p>
                Get a free link and custom landing page to share with your
                audience.
              </p>
            </div>
            <div>
              <h3>📅 No Overbookings</h3>
              <p>
                Syncs with your Google Calendar, so customers can only book you
                when you're available.
              </p>
            </div>
            <div>
              <h3>💰 Get Paid Immediately</h3>
              <p>Funds are deposited to your debit card.</p>
            </div>
          </div>
        </section>

        <section className="section-2">
          <div className="calndr-anim-container">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: calenderUpdateAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid meet"
                }
              }}
              isStopped={false}
              isPaused={false}
            />
          </div>
          <div
            className="bullet-points-container"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <div>
              <h2> How it works </h2>
            </div>
            <div>
              <h3>1. Set your availability.</h3>
              <p>
                Select the days and time you are available, Hopon will
                automatically sync with your calenar.
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end"
              }}
            >
              <h3>2. Set your price.</h3>
              <p style={{ textAlign: "right" }}>
                You choose how much you want to charge for your time. All money
                will be directly transfered to your bank account.
              </p>
            </div>
            <div>
              <h3>3. Share link</h3>
              <p>
                Anyone you share the link with can book an appointment with you
                based on the availability. Have a fun time connecting with
                people that look up to you.
              </p>
            </div>
          </div>
        </section>
        <section className="section-3">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center"
            }}
          >
            <h2>Perfect for: Influencers, Coaches and Experts.</h2>
            <div className="spacer-12" />
            <p>
              Convert website visitors into new clients. Hopon.vc is the easiest
              way to setup your online consulting business.
            </p>
            <button
              className="desktop-visible start-btn"
              onClick={onStartBtnPress}
            >
              Start Now
            </button>
          </div>
          <div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: hanginClinkAnimation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid meet"
                }
              }}
              isStopped={false}
              isPaused={false}
            />
            <button
              className="phone-visible start-btn"
              onClick={onStartBtnPress}
            >
              Start Now
            </button>
          </div>
        </section>
      </div>
      <footer>
        <a href="/">Privacy</a>
        <h3 style={{ color: "#458478" }}>hopon.vc</h3>
        <a href="/">Terms</a>
      </footer>
    </div>
  );
}
