import React, { useState } from "react";
import "./styles.css";
import Header from "./components/Header";
import { useForm } from "react-hook-form";
import ReactGa from "react-ga";
// import FacebookLogin from "react-facebook-login";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function SignUpPage() {
  const query = useQuery();
  const source = query.get("source");
  const { register, handleSubmit, errors } = useForm();
  const [requestState, setRequestState] = useState({
    state: "idle",
    errorMessage: null
  });
  const [fbRequestState] = useState({
    state: "idle",
    errorMessage: null
  });

  const onSubmit = data => {
    if (requestState.state === "inProgress") {
      return;
    }
    ReactGa.event({
      category: "Form Submit",
      action: "Sign Up form submitted."
    });
    setRequestState({ state: "inProgress" });
    let status;
    data.source = source;
    fetch("https://stealth-app-272504.appspot.com/v1/auth/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(response => {
        status = response.status;
        return response.json();
      })
      .then(data => {
        if (status !== 200) {
          // show some error
          setRequestState({ state: "error", errorMessage: data.message });
          return;
        }
        setRequestState({ state: "success" });
      })
      .catch(error => {
        console.error("Error:", error);
        setRequestState({ state: "error", errorMessage: error.message });
      });
  };

  // facebook callback
  // const responseFacebook = response => {
  //   if (response.name) {
  //     let bodyData = new FormData();
  //     bodyData.append("name", response.name);
  //     bodyData.append("emailAddress", response.email);
  //     bodyData.append("userID", response.userID);
  //     bodyData.append("source", source);
  //     ReactGa.event({
  //       category: "Form Submit",
  //       action: "Signing up with Facebook"
  //     });
  //     fetch("https://stealth-app-272504.appspot.com/v1/auth/facebook", {
  //       method: "POST",
  //       body: bodyData
  //     })
  //       .then(res => {
  //         if (res.status === 200 || res.status === 201) {
  //           setFbRequestState({ state: "success" });
  //           return;
  //         } else {
  //           throw Error("Failed to login with Facebook.");
  //         }
  //       })
  //       .then(data => {
  //         setFbRequestState({ state: "success" });
  //       })
  //       .catch(error => {
  //         console.error("Error:", error);
  //         setFbRequestState({ state: "error", errorMessage: error.message });
  //       });
  //   }
  // };

  // function onFbButtonPress() {
  //   setFbRequestState({ state: "inProgress" });
  // }
  return (
    <div className="root">
      <div
        className="container"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <Header />
        <Spacer size={40} />

        {requestState.state === "success" ||
        fbRequestState.state === "success" ? (
          <div style={{ padding: 20, height: "70vh" }}>
            <h2>Waiting for approval.</h2>
            <Spacer size={32} />
            <p>
              Your account is under review.we'll send you an email confirmation
              when it's approved. It usually takes 1-2 days for the approval
              process. We look forward to having you on our platform.
            </p>
          </div>
        ) : (
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 250,
              padding: 24,
              alignSelf: "center",
              justifySelf: "center",
              flex: 1
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <label>First Name</label>
            {errors.firstName && (
              <p className="error-text">{errors.firstName.message}</p>
            )}
            <input
              type="text"
              name="firstName"
              ref={register({
                required: "First name is required.",
                minLength: {
                  value: 3,
                  message: "First name cannot be less than 3 characters."
                },
                maxLength: {
                  value: 100,
                  message: "First name cannot be longer than 100 characters."
                }
              })}
            />
            <label>Last Name</label>
            {errors.lastName && (
              <p className="error-text">{errors.lastName.message}</p>
            )}
            <input
              type="text"
              name="lastName"
              ref={register({
                required: "Last name is required.",
                minLength: {
                  value: 3,
                  message: "Last name cannot be less than 3 characters."
                },
                maxLength: {
                  value: 100,
                  message: "Last name cannot be longer than 100 characters."
                }
              })}
            />
            <label>Email</label>
            {errors.emailAddress && (
              <p className="error-text">{errors.emailAddress.message}</p>
            )}
            <input
              type="text"
              name="emailAddress"
              ref={register({
                required: "Email is required.",
                pattern: { value: /^\S+@\S+$/i, message: "Must be an email." }
              })}
            />
            <label>Password</label>
            {errors.password && (
              <p className="error-text">{errors.password.message}</p>
            )}
            <input
              type="password"
              name="password"
              ref={register({
                required: "Password is required.",
                minLength: {
                  value: 6,
                  message: "Password must be atleast 6 characters."
                },
                maxLength: {
                  value: 30,
                  message: "Password cannot be longer than 30 characters."
                }
              })}
            />
            {requestState.state === "error" && (
              <p className="error-text">{requestState.errorMessage}</p>
            )}
            <button type="submit" style={{ marginTop: 24, marginBottom: 24 }}>
              {requestState.state === "inProgress" ? "Loading..." : "Sign Up"}
            </button>
            {/* <FacebookLogin
              appId="219380072492218"
              autoLoad={true}
              fields="name,email"
              onClick={onFbButtonPress}
              callback={responseFacebook}
            /> */}
            {fbRequestState.state === "error" && (
              <p className="error-text">{requestState.errorMessage}</p>
            )}
            <p style={{ fontSize: 12, textAlign: "center", paddingTop: 8 }}>
              By clicking "Sign Up", you agree to the <u>Terms</u> and{" "}
              <u>Privacy Policy</u>
            </p>
          </form>
        )}
      </div>
      <footer>
        <a href="/">Privacy</a>
        <h3 style={{ color: "#458478" }}>hopon.vc</h3>
        <a href="/">Terms</a>
      </footer>
    </div>
  );
}

function Spacer({ size = 12 }) {
  return <div style={{ width: size, height: size }} />;
}
